export const initializePendo = (userId) => {
  (function(apiKey) {
  (function(p, e, n, d, o) {
    let v, w, x;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];

    for (w = 0; w < v.length; w++) {
      (function(m) {
        o[m] =
          o[m] ||
          function() {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    }

    x = e.createElement(n);
    x.async = true;
    x.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
    e.head.appendChild(x);
  })(window, document, 'script', 'pendo');

  window.pendo.initialize({
    visitor: {
      id: userId,
    },
  });
})('446893b2-26e2-409e-765b-768f31059677');
}