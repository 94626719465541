import waitForElement from './util/waitForElement.js';

let isZendeskOpen = false
let isZendeskInitialized = false

export const initializeZendesk = () => {
	if (isZendeskInitialized) return;

	waitForElement('#launcher', function () {
		window.zE("messenger:on", "open", function () {
			isZendeskOpen = true
		})
		window.zE("messenger:on", "close", function () {
			isZendeskOpen = false
		})
		isZendeskInitialized = true
	});
}

export const openZendeskChat = () => {
	window.zE('messenger', isZendeskOpen ? 'hide' : 'show');
	window.zE('messenger', isZendeskOpen ? 'close' : 'open');
}

export const cleanupZendesk = () => {
	window.$('#launcher').remove()
	isZendeskInitialized = false
}